import { CUSTOMIZE_PRODUCT } from "../constants/productConstants";

//Customize Product
export const customizeReducer = (state = { customizeProduct: [] }, action) => {
  switch (action.type) {
    case CUSTOMIZE_PRODUCT:
      return { customizeProduct: action.payload };
    default:
      return state;
  }
};
