import { LOCATION_CHANGE } from "react-router-redux";
import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_GET_PRICE_FAIL,
  PRODUCT_GET_PRICE_REQUEST,
  PRODUCT_GET_PRICE_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_RECOMMENDED_FAIL,
  PRODUCT_RECOMMENDED_REQUEST,
  PRODUCT_RECOMMENDED_SUCCESS,
} from "../constants/productConstants";

//All Products
export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//All Products
export const recommendedReducer = (state = { recommended: [] }, action) => {
  switch (action.type) {
    case PRODUCT_RECOMMENDED_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_RECOMMENDED_SUCCESS:
      return { loading: false, recommendedProduct: action.payload };
    case PRODUCT_RECOMMENDED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//Single Product
export const productDetailsReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, product: [] };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//Get Price
export const productPriceReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_GET_PRICE_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_GET_PRICE_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_GET_PRICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
