/** @format */

import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

// Importing CSS files for lazy loading support (no broken styles) -Omar
import "./App.css";
import "./screens/reviews.css";
import "./screens/sliderStyles.css";
import "./screens/style.css";
import "./styles/customize.css";
import "./styles/header.css";
import "./styles/markdown.css";

// Changed pages to lazy loaded pages instead of importing all pages at once -Omar
import { Loader } from "./components/Loader";
const Customize_Reworked = lazy(() => import("./layouts/Customize_Reworked"));
const Customize_Details = lazy(() =>
  import("./screens/Customization/Customize_Details")
);
const Customize_Fabric = lazy(() =>
  import("./screens/Customization/Customize_Fabric")
);
const Customize_BottomBar = lazy(() =>
  import("./screens/Customization/Customize_BottomBar")
);
const Customize_Control = lazy(() =>
  import("./screens/Customization/Customize_Control")
);
const Customize_Valance = lazy(() =>
  import("./screens/Customization/Customize_Valance")
);
const Customize_Summary = lazy(() =>
  import("./screens/Customization/Customize_Summary")
);
const Footer = lazy(() => import("./components/Footer"));
const Header = lazy(() => import("./components/Header"));
const CartScreen = lazy(() => import("./screens/CartScreen"));
const ShippingScreen = lazy(() => import("./screens/ShippingScreen"));
const PaymentScreen = lazy(() => import("./screens/PaymentScreen"));
const HelpScreen = lazy(() => import("./screens/HelpScreen"));
const AllShadeScreen = lazy(() => import("./screens/AllShadeScreen"));
const EnvironmentScreen = lazy(() => import("./screens/EnvironmentScreen"));
const ProductScreenDetailed = lazy(() =>
  import("./screens/ProductScreenDetailed")
);
const InstallationScreen = lazy(() =>
  import("./screens/help/InstallationScreen")
);
const MeasuringGuideScreen = lazy(() =>
  import("./screens/help/MeasuringGuideScreen")
);
const NewHome = lazy(() => import("./screens/NewHome"));
const AboutUsScreen = lazy(() => import("./screens/AboutUsScreen"));
const ProductSelectionGuideScreen = lazy(() =>
  import("./screens/help/ProductSelectionGuideScreen")
);
const PicksScreen = lazy(() => import("./screens/PicksScreen"));
const ShippingPolicyScreen = lazy(() =>
  import("./screens/help/ShippingPolicyScreen")
);
const ReturnPolicyScreen = lazy(() =>
  import("./screens/help/ReturnPolicyScreen")
);
const PrivacyPolicy = lazy(() => import("./screens/help/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./screens/help/TermsAndConditions")
);
const WarrantyScreen = lazy(() => import("./screens/help/WarrantyScreen"));
const MotorGuideScreen = lazy(() => import("./screens/help/MotorGuideScreen"));
const CollectionsScreen = lazy(() => import("./screens/CollectionsScreen"));
const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const RecoveryScreen = lazy(() => import("./screens/RecoveryScreen"));
const PasswordResetScreen = lazy(() => import("./screens/PasswordResetScreen"));
const RegisterScreen = lazy(() => import("./screens/RegisterScreen"));
const TeamScreen = lazy(() => import("./screens/TeamScreen"));
const ProfileScreen = lazy(() => import("./screens/ProfileScreen"));
const PlaceOrderScreen = lazy(() => import("./screens/PlaceOrderScreen"));
const RecommendedScreen = lazy(() => import("./screens/RecommendedScreen"));
const ConfirmationScreen = lazy(() => import("./screens/ConfirmationScreen"));
const MyOrderScreen = lazy(() => import("./screens/MyOrderScreen"));
const wallStart = lazy(() =>
  import("./components/wallPaperComponents/startWallPaper")
);
const TheForgeFunc = lazy(() =>
  import("./components/wallPaperComponents/TheForge/TheForge")
);
const Contact = lazy(() => import("./screens/Contact"));
const CustomizeShade = lazy(() => import("./screens/CustomizeShade"));
const FabricSwatch = lazy(() => import("./screens/FabricSwatch"));
const Magazine = lazy(() => import("./screens/Magazine"));
const SwatchCheckout = lazy(() => import("./screens/SwatchCheckout"));
const BlogScreen = lazy(() => import("./screens/BlogScreen"));
const BlogContentScreen = lazy(() => import("./screens/BlogContentScreen"));
// const Customize = lazy(() => import("./screens/Customize"));
// const NotFound = lazy(() => import("./screens/NotFound"));

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none",
          color: "black",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#E61610",
    },
    text: {
      primary: "#000",
      secondary: "#474747",
    },
  },
  typography: {
    fontFamily: "Helvetica W01 Cn, Helvetica, Arial, sans-serif",
    button: {
      fontFamily: "Source Sans Pro",
      fontSize: "1rem",
      border: "1px solid black",
    },
  },
});

const App = () => {
  document.body.style = "background: white;";

  return (
    // forceRefresh = {true}
    // Suspense Component used for lazy loading
    <Suspense fallback={<Loader />}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Header />
            <Route
              path='/test'
              exact
              component={Customize_Reworked}
            />
            <Route
              path='/customize/details'
              exact
              component={Customize_Details}
            />
            <Route
              path='/customize/fabric'
              exact
              component={Customize_Fabric}
            />
            <Route
              path='/customize/bottombar'
              exact
              component={Customize_BottomBar}
            />
            <Route
              path='/customize/control'
              exact
              component={Customize_Control}
            />
            <Route
              path='/customize/valance'
              exact
              component={Customize_Valance}
            />
            <Route
              path='/customize/summary'
              exact
              component={Customize_Summary}
            />
            <Route
              path='/aboutus'
              component={AboutUsScreen}
            />
            <Route
              path='/contact'
              component={Contact}
            />
            <Route
              path='/cart/:program_id?'
              component={CartScreen}
            />
            <Route
              path='/collections/:collection?'
              exact
              component={CollectionsScreen}
            />
            <Route
              path='/payment'
              component={PaymentScreen}
            />
            <Route
              path='/shipping'
              component={ShippingScreen}
            />
            <Route
              path='/environment'
              component={EnvironmentScreen}
            />
            <Route
              path='/help'
              component={HelpScreen}
            />
            <Route
              path='/installation'
              component={InstallationScreen}
            />
            <Route
              path='/productselection'
              component={ProductSelectionGuideScreen}
            />
            <Route
              path='/measuring'
              component={MeasuringGuideScreen}
            />
            <Route
              path='/shippingpolicy'
              component={ShippingPolicyScreen}
            />
            <Route
              path='/returnpolicy'
              component={ReturnPolicyScreen}
            />
            <Route
              path='/warranty'
              component={WarrantyScreen}
            />
            <Route
              path='/motorguide'
              component={MotorGuideScreen}
            />
            <Route
              path='/privacypolicy'
              component={PrivacyPolicy}
            />
            <Route
              path='/termsandconditions'
              component={TermsAndConditions}
            />
            <Route
              path='/picks/:pick'
              component={PicksScreen}
            />
            <Route
              path='/blinds/:query'
              exact
              component={AllShadeScreen}
            />
            <Route
              path='/login'
              component={LoginScreen}
            />
            <Route
              path='/accountRecovery'
              component={RecoveryScreen}
            />
            <Route
              path='/resetPassword'
              component={PasswordResetScreen}
            />
            <Route
              path='/register'
              component={RegisterScreen}
            />
            <Route
              path='/profile'
              component={ProfileScreen}
            />
            <Route
              path='/myorders'
              component={MyOrderScreen}
            />
            <Route
              path='/placeorder'
              component={PlaceOrderScreen}
            />
            <Route
              path='/confirmation'
              component={ConfirmationScreen}
            />
            <Route
              path='/blinds/:type/:category/:name'
              exact
              component={ProductScreenDetailed}
            />
            <Route
              path='/team'
              exact
              component={TeamScreen}
            />
            <Route
              path='/recommended'
              exact
              component={RecommendedScreen}
            />
            <Route
              path='/wallpaperinit'
              exact
              component={wallStart}
            />
            <Route
              path='/wallpapergenerator'
              exact
              component={TheForgeFunc}
            />
            <Route
              path='/'
              exact
              component={NewHome}
            />
            <Route
              path='/newhome'
              exact
              component={NewHome}
            />
            {/* <Route
              path='/customize/:query'
              exact
              component={Customize}
            /> */}
            <Route
              path='/customizeshade'
              exact
              component={CustomizeShade}
            />
            <Route
              path='/fabricswatch'
              exact
              component={FabricSwatch}
            />
            <Route
              path='/magazine'
              exact
              component={Magazine}
            />
            <Route
              path='/swatchcheckout'
              exact
              component={SwatchCheckout}
            />

            <Route
              path='/blog'
              exact
              component={BlogScreen}
            />

            <Route
              path='/blog/:id'
              exact
              component={BlogContentScreen}
            />

            <Footer />
          </CssBaseline>
        </ThemeProvider>
      </Router>
    </Suspense>
  );
};

export default App;
