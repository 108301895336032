export const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case "FILTER_SET":
      return {
        value: action.payload,
      };
    default: // need this for default case
      return {
        ...state,
        //wallpaperFrames: action.payload
      };
  }
};
