export const reviewSumaryReducer = (state = {}, action) => {
  switch (action.type) {
    case "REV_SUM_SET":
      return {
        ...state,
        value: action.payload,
      };
    default: // need this for default case
      return {
        ...state,
        //wallpaperFrames: action.payload
      };
  }
};
