/** @format */

import React from "react";
import { Spinner } from "react-bootstrap";

export const Loader = () => {
  return (
    <>
      <Spinner
        animation='border'
        role='status'
        style={{
          width: "100px",
          height: "100px",
          margin: "auto",
          display: "block",
          opacity: "0.3",
        }}
      ></Spinner>
      <span className='sr-only'>Loading...</span>
    </>
  );
};
